import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, lastValueFrom, map, ReplaySubject, tap } from 'rxjs';

import { ThemeParameters } from '@core/interfaces/theme-parameters';
import { AuthService } from '@core/services/auth.service';
import { ThemeService } from '@core/services/theme.service';
import { environment } from '@environment';
import { OrganizationViewModel, ProfileOrganization } from '@features/models/organization.model';
import { Result } from '@shared/models/result.model';
import { LOCAL_STORAGE_KEYS } from '@shared/utils/constants';

import { ProfileService } from './profile.service';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  private url = `${environment.apis.linkroom}/organizations`;

  private _currentOrganization: OrganizationViewModel | null = null;
  private _currentOrganization$ = new BehaviorSubject<OrganizationViewModel | null>(null);

  private _organizationList: ProfileOrganization[] = [];
  private _organizationList$ = new BehaviorSubject<ProfileOrganization[]>([]);

  get currentOrganization() {
    return this._currentOrganization;
  }

  get currentOrganization$() {
    return this._currentOrganization$.asObservable();
  }

  get organizationList() {
    return this._organizationList;
  }

  get organizationList$() {
    return this._organizationList$.asObservable();
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private themeService: ThemeService,
    private profileService: ProfileService
  ) {
    this.authService.user$.subscribe(async user => {
      if (!user) return;

      try {
        const { data } = await lastValueFrom(this.profileService.listOrganizations(user._id));
        this._organizationList = data;
        this._organizationList$.next(data);
      } catch (error) {
        throw error;
      }
    });
  }

  get(id: string) {
    return this.http.get<Result<OrganizationViewModel>>(`${this.url}/${id}`);
  }

  async selectOrganization(id: string | null) {
    try {
      if (!id) {
        this._currentOrganization = null;
        this._currentOrganization$.next(null);
        return;
      }

      localStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE_CURRENT_ORGANIZATION_ID, id);

      this.get(id)
        .pipe(
          map(response => response.data),
          tap(org => {
            this.setTheme(org);
            this._currentOrganization = org;
            this._currentOrganization$.next(org);
          })
        )
        .subscribe();
    } catch (error) {
      this._currentOrganization = null;
      this._currentOrganization$.next(null);
      throw error;
    }
  }

  private setTheme(organization: OrganizationViewModel) {
    const themeParemeters: ThemeParameters = {
      primaryColor: organization.primaryColor,
      secondaryColor: organization.secondaryColor
    };

    this.themeService.setThemeParameters(themeParemeters);
  }
}
